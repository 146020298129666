import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import Copy, { Bold, ListItem, UnorderedList } from '@nib-components/copy';

import Layout from '../../components/Layout';
import metrics from '../../metrics';

import LifeLivingInsuranceSection, {
  LifeLivingInsuranceSectionProps,
} from '../../page-sections/life-living-insurance-section';
import { ContentHeroPanelProps } from '../../components/ContentHeroPanel';
import { NavContentProps, NavLinkProps } from '../../components/NavContent';
import { ImportantInformationBannerProps } from '../../components/ImportantInformationBanner';
import Link from '@nib-components/link';
import { AddSystemIcon, ExternalLinkSystemIcon } from '@nib/icons';
import { Box, Column, Columns, Section, Stack } from '@nib/layout';
import Heading from '@nib-components/heading';
import Accordion from '@nib-components/accordion';
import WomanOnLaptopCalculatorImage from '../../img/life-and-living/woman-on-laptop-calculator.jpeg';
import { PrimaryButton, SecondaryButton } from '@nib-components/button';
import { colorBlack } from '@nib-components/theme';
import { urlConstants } from '../../constructs/constants';

const title = 'Applying for Life & Living Insurance';
const description =
  'New Zealanders can get peace of mind if they pass away or can’t work anymore with life insurance or illness and income protection cover from nib.';

const contentHeroData: ContentHeroPanelProps = {
  title: (
    <>
      Let’s talk about <span style={{ color: '#82E578' }}>Life & Living insurance.</span>
    </>
  ),
  subtextLeft: 'LIFE INSURANCE',
  subtextRight: 'LIVING INSURANCE',
  description: <>It’s healthy to talk about death. It’s even better to plan for it.</>,
  imageMobile: WomanOnLaptopCalculatorImage,
  imageDesktop: WomanOnLaptopCalculatorImage,
  imageAlt: 'A smiling woman focused on her laptop, engaged in work with a positive demeanor.',
  type: 'banner',
};

const navigationLinks: NavLinkProps[] = [
  {
    text: 'About Life & Living insurance',
    isActive: false,
    href: urlConstants.aboutLifeLivingInsurance,
  },
  {
    text: 'Special offers',
    isActive: false,
    href: urlConstants.currentOfferLifeLivingInsurance,
  },
  {
    text: 'Why get Life & Living insurance',
    isActive: false,
    href: urlConstants.whyLifeLivingInsuranceCoverIsImportant,
  },
  {
    text: 'Estimator Tool',
    isActive: false,
    href: urlConstants.estimateYourCoverageLifeLivingInsurance,
  },
  {
    text: 'For homeowners',
    isActive: false,
    href: urlConstants.lifeLivingHomeowners,
  },
  { text: 'How to apply', isActive: true, href: urlConstants.howToApplyLifeLivingInsurance },
];

const contentData = {
  title: 'How to apply',
  description: (
    <>
      Nobody knows what’s coming around the corner in life. That’s why we offer life insurance &
      living insurance, so you can prepare for the unexpected and help ensure your loved ones are
      taken care of — even if you’re no longer around.
    </>
  ),
};

const steps: StepItem[] = [
  {
    title: 'Get an estimate',
    subtitle: 'Step 1',
    steps: [
      <>Answer 12 questions to see what cover might suit your needs</>,
      <>Find out how much your cover might cost</>,
      <>Use the sliders to adjust the cover to a level or price that suits you</>,
      <>
        When you’re satisfied with your cover selections, click <b>‘Apply Online’</b> to continue
      </>,
    ],
    button: {
      text: 'Go to the estimator tool',
      href: urlConstants.estimateYourCoverageLifeLivingInsurance,
    },
  },
  {
    title: 'Questions? Ask a specialist',
    subtitle: 'Step 2 (optional)',
    steps: [
      <>Get personalised support from the nib team</>,
      <>Check eligibility and get answers to questions via phone, or email</>,
      <>Our team will aim to get back to you within one business day</>,
    ],
    button: {
      text: 'Message our team',
      href: urlConstants.lifeLivingInsuranceContactUs,
    },
  },
  {
    title: 'Fill out an application',
    subtitle: 'Step 3',
    steps: [
      <>
        After receiving your estimate, click <b>‘Apply Online’</b> in the Estimator tool to continue{' '}
      </>,
      <>We will ask about your health history, beneficiaries, and personal details. ​</>,
      <>
        If you prefer, you can also apply over the phone with one of our specialists, who will guide
        you through the process.​
      </>,
      <>If you need assistance at any stage, our specialist team is available to help.​</>,
      <>
        Once submitted, your application will be reviewed, and you’ll receive Temporary Death Cover
        while it’s being assessed.​
      </>,
    ],
  },
];

const frequentlyAskedQuestionsData: FAQ = {
  title: 'Important things to know',
  description: (
    <>
      Before you apply, it may be helpful to check if you’re eligible for Life & Living insurance
      and learn more about the process. Here are some common questions and things you should know
      about applying for Life & Living insurance with nib.
    </>
  ),
  showIcon: false,
  questions: [
    {
      title: 'Am I eligible?',
      content: (
        <>
          <Copy measure={false}>
            To apply for nib Life insurance & Living insurance online or through 0800 555 642,
            you'll need to complete an application form and be:​
          </Copy>
          <UnorderedList>
            <ListItem>Living in New Zealand and have one of the following:​</ListItem>
            <ListItem>New Zealand or Australian passport/citizenship​</ListItem>
            <ListItem>
              New Zealand or Australian Permanent Resident Visa (with no travel conditions on your
              visa)​
            </ListItem>
            <ListItem>
              New Zealand Resident Visa (for Life cover and/or Serious Illness Trauma cover only)​
            </ListItem>
            <ListItem>18-70 years old to apply for Life Insurance (Life cover)​</ListItem>
            <ListItem>
              18-60 years old to apply for Living Insurance (Serious Illness Trauma cover or Income
              Protection Illness cover)​
            </ListItem>
            <ListItem>
              Employed for at least 25 hours per week for a single employer to apply for Living
              Insurance (Income Protection Illness cover)​
            </ListItem>
          </UnorderedList>
        </>
      ),
    },
    {
      title: 'What happens while my application is being reviewed?',
      content: (
        <>
          While your application is being reviewed, we may request additional information, such as
          medical records or further details about your health. During this time, you will also
          receive{' '}
          <Link
            href={urlConstants.temporaryAccidentalDeathCoverPDF}
            icon={ExternalLinkSystemIcon}
            target="_blank"
          >
            Temporary Accidental Death Cover
          </Link>{' '}
          as soon as you submit your application.​
          <br />
          <br />
          Temporary Accidental Death Cover is complimentary and provided for a limited time while
          your Life & Living Insurance application is being assessed. This cover protects you if you
          pass away due to a non-medical, unexpected accidental injury. In this case, your proposed
          beneficiary (or your estate if no beneficiary is named) will receive the amount of Life
          Cover you applied for, along with $15,000 Funeral Cover, up to a maximum of $500,000.
        </>
      ),
    },
    {
      title: 'What is underwriting?',
      content: (
        <>
          Underwriting is the process we use to assess your application. This includes reviewing
          your health, lifestyle, and other factors to determine the cover and terms we can
          provide.​
        </>
      ),
    },
    {
      title: 'Am I locked in?',
      content: (
        <>
          No, you’re not locked in. You can cancel your policy at any time. If you cancel within the
          30-day free-look period, any premiums paid will be refunded.​
        </>
      ),
    },
    {
      title: 'When is my first premium due?',
      content: (
        <>
          Your first premium is due once your policy is activated. You’ll receive a confirmation
          with all payment details, including the date and amount, so you know exactly what to
          expect.​
        </>
      ),
    },
    {
      title: 'Will my premium go up?',
      content: (
        <Stack space={3}>
          <Bold>Your premium will usually increase each year</Bold>
          <Copy measure={false}>
            Your premium is not fixed. Even if you maintain the same level of cover, your premium
            will typically increase as the insured person gets older. If you'd like an estimate of
            how your premiums may change over time, you can ask us for an indication. We may also
            adjust premiums as outlined in the section ‘Making changes to the insurance agreement’.
          </Copy>
          <Bold>Your cover will generally increase each year</Bold>
          <Copy measure={false}>
            To help maintain the value of your insurance, we automatically increase your cover
            amount each year on the anniversary of your Life & Living Insurance start date, while
            you’re eligible for this indexation. This increase is based on the Consumer Price Index
            (CPI), with a minimum of 1% and a maximum of 7%.
          </Copy>
          <Copy measure={false}>
            Please note that complimentary Funeral Expenses cover does not increase and remains a
            fixed payment of $15,000.
          </Copy>
        </Stack>
      ),
    },
    {
      title: 'When can I change my cover?',
      content: (
        <>
          You can reduce your cover at any time or apply to increase your cover or add new benefits,
          subject to underwriting. Simply contact us, and we’ll help to adjust your policy.​
        </>
      ),
    },
    {
      title: 'How do I claim if I need to?',
      content: (
        <>
          If you need to make a claim, simply contact our claims team, and they’ll guide you through
          the process. You’ll need to provide relevant documents, such as medical reports, proof of
          income, or evidence of the event you’re claiming for.​
          <br />
          <br />
          If your application is approved and your policy is active, you can make a claim
          immediately, provided the event you're claiming for meets the terms and conditions of your
          policy. Some cover types may have a stand down period before certain benefits can be
          claimed.​{' '}
        </>
      ),
    },
    {
      title: 'Who receives the claim payment?',
      content: (
        <>
          For Life Cover, the claim payment goes to your nominated beneficiary, if you have
          nominated one, or to your estate. For Serious Illness Trauma Cover or Income Protection
          Cover, the payment is made directly to you, the policyowner, so you can use it as needed.​
        </>
      ),
    },
    {
      title: 'Where can I learn more?',
      content: (
        <>
          You can learn more about Life & Living Insurance by visiting our website, speaking with
          our team, or checking out our{' '}
          <Link
            href={urlConstants.lifeAndLivingInsuranceWording}
            icon={ExternalLinkSystemIcon}
            target="_blank"
          >
            policy document
          </Link>{' '}
          and{' '}
          <Link
            href={urlConstants.lifeAndLivingInsuranceFactSheet}
            icon={ExternalLinkSystemIcon}
            target="_blank"
          >
            fact sheet
          </Link>
          . We’re here to help answer any questions you may have.
        </>
      ),
    },
  ],
};

const navigation: NavContentProps = {
  title: 'Life & Living insurance',
  homeHref: urlConstants.compareLifeAndLivingInsurance,
  primaryButton: { text: 'Apply now', href: urlConstants.estimateYourCoverageLifeLivingInsurance },
  secondaryButton: { text: 'Contact us', href: urlConstants.lifeLivingInsuranceContactUs },
  navigation: navigationLinks,
};

const importantInformationData: ImportantInformationBannerProps = {
  title: 'Important Information',
  description: [
    <Copy measure={false} size="small">
      Only the main features and benefits of the Life & Living Insurance is outlined here. For a
      full explanation of all benefits, exclusions and other terms and conditions of the cover, view
      the{' '}
      <Link href={urlConstants.lifeAndLivingInsuranceWording} icon={ExternalLinkSystemIcon}>
        Life & Living Insurance wording
      </Link>
      .
    </Copy>,
    <Copy measure={false} size="small">
      Life & Living Insurance is provided by nib nz insurance limited and is a related company of
      nib nz limited (“nib”). nib nz insurance limited issues and underwrites Life & Living
      Insurance products, and is solely responsible for claims under the cover. nib sells and may
      advise you on these products. Find out more about{' '}
      <Link href={urlConstants.financialStrength}>
        financial strength and financial advice with nib
      </Link>
      .
    </Copy>,
  ],
};

const readyToApplyData = {
  title: 'Ready to apply?',
  description: (
    <>
      Start your online application today! After you submit your information, a member of our team
      will review it to determine if we can provide you with cover.
    </>
  ),
  button: {
    text: 'Get started',
    href: urlConstants.estimateYourCoverageLifeLivingInsurance,
  },
};

const lifeLivingSectionData: LifeLivingInsuranceSectionProps = {
  heroContent: contentHeroData,
  navigation: navigation,
  importantInformationData: importantInformationData,
};

interface FAQ {
  title: string;
  description: JSX.Element;
  showIcon: boolean;
  questions: { title: string; content: JSX.Element }[];
}

interface StepItem {
  title: string;
  subtitle: string;
  steps: JSX.Element[];
  image?: { src: string; alt: string };
  button?: { text: string; href: string };
}

const StepImage = styled.img`
  width: 100%;
  max-height: 250px;
  object-fit: contain;
`;

const LifeAndLivingApply = (): JSX.Element => {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    '@id': 'WebPage',
    identifier: 'https://www.nib.co.nz/life-cover-options/how-to-apply',
    url: 'https://www.nib.co.nz/life-cover-options/how-to-apply',
    description:
      'New Zealanders can get peace of mind if they pass away or can’t work anymore with life insurance or illness and income protection cover from nib.',
    name: ['Applying for Life & Living Insurance | nib'],
    isPartOf: 'https://www.nib.co.nz',
  };
  const JSONschema = JSON.stringify(schema);

  return (
    <Layout>
      <Helmet>
        <title>{title} | nib</title>
        <meta name="description" content={description} />
        <script type="application/ld+json">{JSONschema}</script>
      </Helmet>
      <LifeLivingInsuranceSection {...lifeLivingSectionData}>
        <MainContent />
      </LifeLivingInsuranceSection>
    </Layout>
  );
};

const MainContent = () => {
  return (
    <Section role="section" background="trueWhite">
      <Stack space={8}>
        <Box display="flex" justifyContent="start" flexDirection={'column'} gap="4" padding="3">
          <Stack space={4}>
            <Box display="flex" justifyContent={'start'}>
              <Heading color="trueGreen" size={{ xs: 2 }} component="h2">
                {contentData.title}
              </Heading>
            </Box>
            <StepsColumn />
          </Stack>
        </Box>
        <FAQSection {...frequentlyAskedQuestionsData} />
        <ReadyToApply />
      </Stack>
    </Section>
  );
};

const StepsColumn = () => {
  return (
    <Stack space={8}>
      {steps.map((step, index) => (
        <StepTile key={index} {...step} />
      ))}
    </Stack>
  );
};

const StepTile = (step: StepItem) => {
  return (
    <Stack space={5}>
      <Columns space={5}>
        <Column flex={true} width={step.image?.src ? '1/5' : 'content'}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <StepImage src={step?.image?.src} alt={step?.image?.alt} />
          </Box>
        </Column>
        <Column flex={true}>
          <Box display="flex" flexDirection="column" justifyContent="center" gap={1}>
            <Bold color="trueGreen" size="small" transform="uppercase">
              {step.subtitle}
            </Bold>
            <Heading color="trueGreen" size={{ xs: 3, md: 2, xl: 3 }} component="h3">
              {step.title}
            </Heading>
            <Box display={{ xs: 'none', xl: 'block' }}>
              <Stack space={4}>
                <UnorderedList space={3}>
                  {step.steps.map((step, index) => (
                    <ListItem color={colorBlack} key={index}>
                      {step}
                    </ListItem>
                  ))}
                </UnorderedList>
                {step.button && (
                  <SecondaryButton href={step?.button?.href}>{step?.button?.text}</SecondaryButton>
                )}
              </Stack>
            </Box>
          </Box>
        </Column>
      </Columns>

      <Box display={{ xs: 'block', xl: 'none' }}>
        <Stack space={4}>
          <UnorderedList space={3}>
            {step.steps.map((step, index) => (
              <ListItem color={colorBlack} key={index}>
                {step}
              </ListItem>
            ))}
          </UnorderedList>
          {step.button && (
            <SecondaryButton href={step?.button?.href}>{step?.button?.text}</SecondaryButton>
          )}
        </Stack>
      </Box>
    </Stack>
  );
};

const FAQSection = (faq: FAQ) => {
  return (
    <Box padding={3}>
      <Stack space={4}>
        <Box display="flex" flexDirection="column" gap="4" padding="3" color="deepBlack">
          <Heading color="trueGreen" size={{ xs: 2 }} component="h2">
            {faq.title}
          </Heading>
          {faq.description}
        </Box>
        <Box>
          <Accordion borderTop={false} borderBottom multiExpanded={false}>
            {faq.questions.map((question, index) => (
              <Accordion.Item
                key={index}
                title={question.title}
                icon={faq.showIcon ? AddSystemIcon : undefined}
              >
                {question.content}
              </Accordion.Item>
            ))}
          </Accordion>
        </Box>
      </Stack>
    </Box>
  );
};

const ReadyToApply = () => {
  return (
    <Box display="flex" flexDirection={{ xs: 'column', xxl: 'row-reverse' }} gap="4" padding="3">
      <Stack space={4}>
        <Box display="flex" justifyContent={'start'}>
          <Heading color="trueGreen" size={{ xs: 2 }} component="h2">
            {readyToApplyData.title}
          </Heading>
        </Box>
        <Box display="flex" justifyContent="center">
          <Copy measure={false} color="deepBlack">
            {readyToApplyData.description}
          </Copy>
        </Box>
        <Box>
          <PrimaryButton href={readyToApplyData.button.href}>
            {readyToApplyData.button.text}
          </PrimaryButton>
        </Box>
      </Stack>
    </Box>
  );
};

export default metrics({ pageName: 'life-and-living-apply' })(LifeAndLivingApply);
